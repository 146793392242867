import React, { useRef, useEffect, useState } from "react";
import ResponsiveCanvas from "../components/responsive-canvas";
import { normalizeAngle } from "../utils/geometry";
import { Color } from "three";
import { motion } from "framer-motion";

const Home = () => {
  const textContainerRef = useRef(null);
  const textRef = useRef(null);
  const subTextRef = useRef(null);
  const timeRef = useRef(0);
  const rotationAngleRef = useRef(0);
  const [rotationAngle, setRotationAngle] = useState(0);
  const [triggerGlow, setTriggerGlow] = useState(false);
  const [glowIndex, setGlowIndex] = useState(-1);
  const glowAlreadyTriggeredRef = useRef(false);

  const isDraggingRef = useRef(false);
  const prevMouseXRef = useRef(0);
  const rotationSpeedRef = useRef(0);

  const themeChangeHandler = (scene, theme) => {
    scene.background = new Color(theme === "dark" ? "#000000" : "#ffffff");

    scene.traverse((object) => {
      if (object.isMesh) {
        object.material.color.set(theme === "dark" ? "#ffffff" : "#000000");
      }
    });
  };

  const handlePointerDown = (event) => {
    const canvas = document.querySelector('[data-name="mainCanvas"]');
    if (!canvas) return;

    const rect = canvas.getBoundingClientRect();
    const mouseX = event.clientX;
    const mouseY = event.clientY;

    if (
      mouseX >= rect.left &&
      mouseX <= rect.right &&
      mouseY >= rect.top &&
      mouseY <= rect.bottom
    ) {
      isDraggingRef.current = true;
      prevMouseXRef.current = mouseX;
      glowAlreadyTriggeredRef.current = false;
      event.preventDefault();
    }
  };

  const SPEED_SENSITIVITY = 0.2;

  const handlePointerMove = (event) => {
    const canvas = document.querySelector('[data-name="mainCanvas"]');
    if (!canvas) return;

    const rect = canvas.getBoundingClientRect();
    const mouseX = event.clientX;
    const mouseY = event.clientY;

    if (
      mouseX < rect.left ||
      mouseX > rect.right ||
      mouseY < rect.top ||
      mouseY > rect.bottom
    ) {
      isDraggingRef.current = false;
    }

    if (!isDraggingRef.current) return;

    const deltaX = prevMouseXRef.current - event.clientX;
    prevMouseXRef.current = event.clientX;

    rotationSpeedRef.current = deltaX * SPEED_SENSITIVITY;
  };

  const handlePointerUp = () => {
    if (isDraggingRef.current) {
      isDraggingRef.current = false;
    }
  };

  const MIN_SPEED = 0.1;
  const ANGLE_TOLERANCE = 1e-4;
  const INITIAL_ROTATION_SPEED = 0.35;
  const DAMPING_COEFFICIENT = 1.2;

  const customCameraBehavior = (camera, deltaTime) => {
    const radius = 100 * Math.sqrt(2);
    const height = camera.position.z;
    const targetAngle = 0;

    const currentAngle = normalizeAngle(timeRef.current);

    if (!isDraggingRef.current) {
      let angleDifference = normalizeAngle(targetAngle - currentAngle);

      if (rotationSpeedRef.current > 0 && angleDifference < 0) {
        angleDifference += 2 * Math.PI;
      } else if (rotationSpeedRef.current < 0 && angleDifference > 0) {
        angleDifference -= 2 * Math.PI;
      }

      const epsilon = 1e-4;
      const angleDifferenceSafe = Math.abs(angleDifference) > epsilon ? angleDifference : (angleDifference >= 0 ? epsilon : -epsilon);

      const angularAcceleration = -((rotationSpeedRef.current* DAMPING_COEFFICIENT) ** 2) / (2 * angleDifferenceSafe);

      rotationSpeedRef.current += angularAcceleration * deltaTime;

      if ((rotationSpeedRef.current > 0 && rotationSpeedRef.current + angularAcceleration * deltaTime < 0) ||
          (rotationSpeedRef.current < 0 && rotationSpeedRef.current + angularAcceleration * deltaTime > 0)) {
        rotationSpeedRef.current = 0;
      }
    }
    
    timeRef.current += deltaTime * rotationSpeedRef.current;

    camera.position.set(
      radius * Math.cos(timeRef.current - Math.PI / 4),
      radius * Math.sin(timeRef.current - Math.PI / 4),
      height
    );

    camera.lookAt(0, 0, 1);
    camera.up.set(0, 0, 1);

    const angleRemainder = Math.abs(timeRef.current / (2 * Math.PI) - Math.round(timeRef.current / (2 * Math.PI)));
    if (Math.abs(rotationSpeedRef.current) < MIN_SPEED && angleRemainder < ANGLE_TOLERANCE && !glowAlreadyTriggeredRef.current && !isDraggingRef.current) {
      rotationSpeedRef.current = 0;
      timeRef.current = targetAngle;

      camera.position.set(
        radius * Math.cos(-Math.PI / 4),
        radius * Math.sin(-Math.PI / 4),
        height
      );

      camera.lookAt(0, 0, 1);
      camera.up.set(0, 0, 1);

      setTriggerGlow(true);
      glowAlreadyTriggeredRef.current = true;

      return;
    }

    rotationAngleRef.current = timeRef.current;
  };

  useEffect(() => {
    let timeout = setTimeout(() => {
      rotationSpeedRef.current = INITIAL_ROTATION_SPEED;
    }, 1000);

    return () => clearTimeout(timeout);
  }, []);

  const ASPECT_RATIO = 0.59237536656;
  const SIZE_RATIO = 0.5;

  const customHandleResize = (camera) => {
    const canvas = document.querySelector('[data-name="mainCanvas"]');
    if (canvas) {
      const parent = canvas.parentElement;
      const parentParent = parent.parentElement;

      const rect = parentParent.getBoundingClientRect();
      const width = rect.width;
      const height = rect.height;

      if (width / height > ASPECT_RATIO) {
        parent.style.width = `${height * ASPECT_RATIO * SIZE_RATIO}px`;
        parent.style.height = `${height * SIZE_RATIO}px`;
      } else {
        parent.style.width = `${width * SIZE_RATIO}px`;
        parent.style.height = `${width / ASPECT_RATIO * SIZE_RATIO}px`;
      }

      camera.aspect = ASPECT_RATIO;
      camera.updateProjectionMatrix();
    }
  };

  const PADDING_RATIO = 0.1;

  const positionTextContainer = () => {
    const mainCanvasParent = document.querySelector('[data-name="mainCanvas"]').parentElement;
    const textContainer = textContainerRef.current;

    if (mainCanvasParent && textContainer) {
      const canvasRect = mainCanvasParent.getBoundingClientRect();
      const windowWidth = window.innerWidth;

      const leftOffset = canvasRect.left;
      const canvasWidth = canvasRect.width;

      const containerWidth = windowWidth - (leftOffset * 2 + canvasWidth * PADDING_RATIO + canvasWidth);

      textContainer.style.position = "absolute";
      textContainer.style.left = `${canvasRect.right + canvasWidth * PADDING_RATIO}px`;
      textContainer.style.width = `${containerWidth}px`;
    }
  };

  const resizeText = () => {
    const container = textContainerRef.current;
    const text = textRef.current;
    const subText = subTextRef.current;

    if (container && text) {
      text.style.transform = "scale(1)";

      const containerWidth = container.offsetWidth;
      const containerHeight = container.offsetHeight;

      const textWidth = text.offsetWidth;
      const textHeight = text.offsetHeight;

      const scale = Math.min(
        containerWidth / textWidth,
        containerHeight / textHeight
      );

      text.style.transform = `scale(${scale})`;
      subText.style.transform = `scale(${scale})`;
    }
  };

  const alignSubText = () => {
    const textElement = textRef.current;
    const subTextElement = subTextRef.current;

    if (textElement && subTextElement) {
      const textRect = textElement.getBoundingClientRect();

      subTextElement.style.top = `${textRect.bottom - textRect.height * 0.1}px`;
    }
  };

  useEffect(() => {
    const handleResize = () => {
      positionTextContainer();
      resizeText();
      alignSubText();
    };

    const debounce = (func, delay) => {
      let timeout;
      return (...args) => {
        clearTimeout(timeout);
        timeout = setTimeout(() => func(...args), delay);
      };
    };

    const debouncedResize = debounce(handleResize, 0);

    handleResize();

    window.addEventListener("resize", debouncedResize);

    const canvasContainer = document.getElementById("canvas-container");
    const observer = new MutationObserver(() => {
      debouncedResize();
    });

    if (canvasContainer) {
      observer.observe(canvasContainer, {
        attributes: true,
        childList: true,
        subtree: false,
      });
    }

    return () => {
      window.removeEventListener("resize", debouncedResize);
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    const canvas = document.querySelector('[data-name="mainCanvas"]');
    if (!canvas) return;

    canvas.addEventListener('mousedown', handlePointerDown);
    window.addEventListener('mousemove', handlePointerMove);
    window.addEventListener('mouseup', handlePointerUp);

    canvas.addEventListener('touchstart', handlePointerDown);
    window.addEventListener('touchmove', handlePointerMove);
    window.addEventListener('touchend', handlePointerUp);

    return () => {
      canvas.removeEventListener('mousedown', handlePointerDown);
      window.removeEventListener('mousemove', handlePointerMove);
      window.removeEventListener('mouseup', handlePointerUp);

      canvas.removeEventListener('touchstart', handlePointerDown);
      window.removeEventListener('touchmove', handlePointerMove);
      window.removeEventListener('touchend', handlePointerUp);
    };
  }, []);

  useEffect(() => {
    let animationFrameId;

    const updateRotationAngle = () => {
      setRotationAngle(rotationAngleRef.current);
      animationFrameId = requestAnimationFrame(updateRotationAngle);
    };

    updateRotationAngle();

    return () => {
      cancelAnimationFrame(animationFrameId);
    };
  }, []);

  useEffect(() => {
    if (triggerGlow) {
      let index = 0;
      const wordLength = "xNilio".length;

      const interval = setInterval(() => {
        setGlowIndex(index);
        index++;

        if (index > wordLength) {
          clearInterval(interval);
          setGlowIndex(-1);
          setTriggerGlow(false);
        }
      }, 100);

      return () => clearInterval(interval);
    }
  }, [triggerGlow]);

  return (
    <div
      className="w-full h-screen"
      style={{
        position: "relative",
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
      }}
    >
      <div
        className="w-1/2 h-full"
        id="canvas-container"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ResponsiveCanvas
          name="mainCanvas"
          url="assets/xnilio_logo.ply"
          themeChangeHandler={themeChangeHandler}
          enableControls={false}
          initialCameraPosition={[99.7632, -100, 100]}
          initialCameraUp={[0, 0, 1]}
          initialCameraRotation={[(Math.PI / 180) * 56, 0, (Math.PI / 180) * 45]}
          initialCameraFocalLength={[1256]}
          initialTarget={[0, 0, 1]}
          customCameraBehavior={customCameraBehavior}
          customHandleResize={customHandleResize}
          lightColor="#ffffff"
          ambientIntensity={10.0}
          directionalIntensity={0.0}
        />
      </div>

      <div
        ref={textContainerRef}
        className="h-full font-bold text-center align-items"
        style={{
          fontSize: "min(calc(10vw), calc(0.1 * var(--container-height)))",
          color: "inherit",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <span
          ref={textRef}
          style={{
            display: "inline-block",
            whiteSpace: "nowrap",
            fontSize: "1rem",
            transform: "scale(1)",
            transformOrigin: "center",
            position: "absolute",
            userSelect: "none",
          }}
          id="dynamic-text"
        >
          {Array.from("xNilio").map((char, index) => {
            const dynamicTextElement = document.getElementById("dynamic-text");
            const shadowBase = `0 0 ${Math.ceil((dynamicTextElement?.offsetHeight || 0) * 0.05)}px var(--primary-accent)`;

            const isDarkTheme = document.documentElement.classList.contains("dark");
            const repetitions = isDarkTheme ? 2 : 1;

            const stackedShadows = Array.from({ length: repetitions })
              .map(() => shadowBase)
              .join(", ");

            return (
              <motion.span
                key={index}
                animate={{
                  textShadow: glowIndex === index ? stackedShadows : "none",
                }}
                transition={{
                  duration: 0.15,
                  ease: "easeInOut",
                }}
                style={{
                  display: "inline-block",
                  whiteSpace: "nowrap",
                  fontSize: "inherit",
                  transformOrigin: "center",
                  userSelect: "none",
                  color: "inherit",
                }}
              >
                {char}
              </motion.span>
            );
          })}
        </span>

        <span
          ref={subTextRef}
          style={{
            position: "absolute",
            fontSize: ".1rem",
            color: "var(--text-color)",
            textAlign: "center",
            whiteSpace: "nowrap",
          }}
        >
          Generated Products, From Nothing
        </span>
      </div>
    </div>
  );
};

export default Home;
