import React, { createContext, useState, useEffect } from "react";

const systemPrefersDarkQuery = window.matchMedia("(prefers-color-scheme: dark)");

export const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState(systemPrefersDarkQuery.matches ? "dark" : "light");

  useEffect(() => {
    const root = document.documentElement;

    if (theme === "dark") {
      root.classList.add("dark");
    } else {
      root.classList.remove("dark");
    }

    const handleSystemThemeChange = (e) => {
      const newSystemTheme = e.matches ? "dark" : "light";
      setTheme(newSystemTheme);
    };

    systemPrefersDarkQuery.addEventListener("change", handleSystemThemeChange);

    return () => {
      systemPrefersDarkQuery.removeEventListener("change", handleSystemThemeChange);
    };
  }, [theme]);

  const toggleTheme = () => {
    const newTheme = theme === "light" ? "dark" : "light";
    setTheme(newTheme);
  };

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};
