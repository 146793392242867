import React from "react";
import { createRoot } from "react-dom/client";
import "./styles/index.css";
import App from "./app.js";
import { ThemeProvider } from "./context/theme-context";

const root = createRoot(document.getElementById("root"));

root.render(
  <ThemeProvider>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </ThemeProvider>
);
