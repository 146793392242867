import React, { useContext } from "react";
import { ThemeContext } from "./context/theme-context";
import Home from "./pages/home";

function App() {
  const { theme } = useContext(ThemeContext);

  return (
    <div className={`w-full h-screen bg-${theme === "dark" ? "gray-800" : "gray-800"}`}>
      <Home />
    </div>
  );
}

export default App;
