import React, { useContext, useEffect, useState } from "react";
import { useLoader, useThree, useFrame } from "@react-three/fiber";
import { TrackballControls } from "@react-three/drei";
import { STLLoader } from "three/examples/jsm/loaders/STLLoader";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";
import { PLYLoader } from "three/examples/jsm/loaders/PLYLoader";
import { ThemeContext } from "../context/theme-context";

const MeshModel = ({ url, onLoad }) => {
  const extension = url.split(".").pop().toLowerCase();

  const loaderMap = {
    stl: STLLoader,
    ply: PLYLoader,
    obj: OBJLoader,
  };

  const Loader = loaderMap[extension];
  if (!Loader) {
    throw new Error(`Unsupported file format: ${extension}`);
  }

  const model = useLoader(Loader, url);

  useEffect(() => {
    if (onLoad) onLoad();
  }, [onLoad]);

  if (extension === "obj") {
    return <primitive object={model} />;
  } else {
    return (
      <mesh geometry={model}>
        <meshStandardMaterial />
      </mesh>
    );
  }
};

const MeshViewer = ({
  url,
  themeChangeHandler,
  enableControls = true,
  initialCameraPosition = [10, 10, 10],
  initialCameraUp = [0, 1, 0],
  initialCameraRotation = [0, 0, 0],
  initialCameraFocalLength = [50],
  initialTarget = [0, 0, 0],
  customCameraBehavior = null,
  customHandleResize = null,
  lightColor = "#ffffff",
  ambientIntensity = 0.5,
  directionalIntensity = 1,
}) => {
  const { theme } = useContext(ThemeContext);
  const { camera, scene } = useThree();
  const controlsRef = React.useRef();
  const [modelLoaded, setModelLoaded] = useState(false);

  useEffect(() => {
    camera.position.set(...initialCameraPosition);
    camera.setFocalLength(...initialCameraFocalLength);
    camera.up.set(...initialCameraUp);
    camera.rotation.set(...initialCameraRotation);

    if (controlsRef.current) {
      controlsRef.current.target.set(...initialTarget);
      controlsRef.current.enablePan = enableControls;
      controlsRef.current.enableZoom = enableControls;
      controlsRef.current.enableRotate = enableControls;
      controlsRef.current.enabled = enableControls;
      controlsRef.current.update();
    }

    if (themeChangeHandler) {
      themeChangeHandler(scene, theme);
    }

    if (customHandleResize) {
      const resizeListener = () => {
        customHandleResize(camera);
      };

      window.addEventListener("resize", resizeListener);

      resizeListener();
    }
  }, [
    camera,
    scene,
    theme,
    themeChangeHandler,
    initialCameraPosition,
    initialCameraUp,
    initialCameraRotation,
    initialCameraFocalLength,
    initialTarget,
    enableControls,
  ]);

  useEffect(() => {
    if (modelLoaded) {
      window.dispatchEvent(new Event("resize"));
    }
  }, [modelLoaded]);

  useFrame((state, delta) => {
    if (customCameraBehavior) {
      customCameraBehavior(camera, delta);
    }
  });

  return (
    <>
      <ambientLight color={lightColor} intensity={ambientIntensity} />
      <directionalLight
        color={lightColor}
        intensity={directionalIntensity}
        position={[10, 10, 10]}
      />
      <MeshModel url={url} onLoad={() => setModelLoaded(true)} />
      <TrackballControls ref={controlsRef} />
    </>
  );
};

export default MeshViewer;
