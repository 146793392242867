import React, { useRef } from "react";
import { Canvas } from "@react-three/fiber";
import MeshViewer from "./mesh-viewer";

const ResponsiveCanvas = ({
  name,
  url,
  themeChangeHandler,
  enableControls = true,
  initialCameraPosition = [10, 10, 10],
  initialCameraUp = [0, 1, 0],
  initialCameraRotation = [0, 0, 0],
  initialCameraFocalLength = [50],
  initialTarget = [0, 0, 0],
  customCameraBehavior = null,
  customHandleResize = null,
  lightColor = "#ffffff",
  ambientIntensity = 0.5,
  directionalIntensity = 1.0,
}) => {
  const containerRef = useRef();

  return (
    <div
      ref={containerRef}
      className="w-full h-full"
      style={{
        boxSizing: "border-box",
        width: "100%",
        height: "100%",
      }}
    >
      <Canvas data-name={name}>
        <MeshViewer
          url={url}
          themeChangeHandler={themeChangeHandler}
          enableControls={enableControls}
          initialCameraPosition={initialCameraPosition}
          initialCameraUp={initialCameraUp}
          initialCameraRotation={initialCameraRotation}
          initialCameraFocalLength={initialCameraFocalLength}
          initialTarget={initialTarget}
          customCameraBehavior={customCameraBehavior}
          customHandleResize={customHandleResize}
          lightColor={lightColor}
          ambientIntensity={ambientIntensity}
          directionalIntensity={directionalIntensity}
        />
      </Canvas>
    </div>
  );
};

export default ResponsiveCanvas;
